import { useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import FeedIcon from '@mui/icons-material/Feed';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { AppBar, Avatar, Badge, Button, InputAdornment } from '@mui/material';

import { Icon } from '@eon-home/react-library';

import { Routes } from '@api/tools';

import { Breakpoint } from '@tools/enums/breakpoints';
import { MenuClasses } from '@tools/enums/menu';

import SelectLanguage from '@components/select-language';
import { ControlledTextField } from '@components/textfield';
import useAuthContext from '@context/auth-provider';
import useUserContext from '@context/user-provider';
import { toggleElementClass } from '@tools/utils';

import DoubleFactorIcon from '@assets/icons/2fa.svg';
import EonLogo from '@assets/icons/eon-logo.svg';

import styles from '@components/header/index.module.scss';

export const Header = () => {
    const { t } = useTranslation();
    const { control, handleSubmit } = useForm();
    const { userData } = useUserContext();

    const { signOut } = useAuthContext();

    const navigate = useNavigate();

    const onSubmit = ({ searchInput }: { searchInput: string }) => {
        if (searchInput !== '') {
            navigate(`/dashboard/search/${encodeURIComponent(searchInput)}`);
        }
    };

    const toggleExpanded = useCallback(
        () => toggleElementClass(document.body, MenuClasses.EXPANDED),
        [],
    );

    const removeMenu = useCallback(() => {
        if (window.innerWidth <= Breakpoint.MD) {
            document.body.classList.remove(MenuClasses.EXPANDED);
        }
    }, []);

    return (
        <AppBar className={styles.header}>
            <div className={`header-left ${styles.header__left}`}>
                <Link to={Routes.DASHBOARD} className={styles.logo}>
                    <Icon src={EonLogo} />
                </Link>

                <div className={styles.languages}>
                    <SelectLanguage />
                </div>
            </div>
            <div className={styles.header__middle}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={styles.header__search}
                >
                    <ControlledTextField
                        name="searchInput"
                        variant="outlined"
                        control={control}
                        data-testid="header-search-input"
                        InputProps={{
                            className: styles.header__searchfield,
                            placeholder: t('common.headerSearch'),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon
                                        className={styles.header__searchicon}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
            </div>

            <div className={styles.header__right}>
                <div className={styles.health}>
                    <Badge className={styles.badge}>
                        <FeedIcon />
                    </Badge>
                </div>

                {userData?.email && (
                    <Link
                        className={styles.account}
                        onClick={removeMenu}
                        to={Routes.PROFILE_SUMMARY}
                        data-testid="profile-summary-link"
                    >
                        <span
                            className={styles.account_email}
                            data-testid="account-email"
                        >
                            {userData.email}
                        </span>

                        <Avatar
                            className={styles.avatar}
                            data-testid="account-avatar"
                        >
                            {userData.multiFactorAuthentication ? (
                                <Icon src={DoubleFactorIcon} />
                            ) : (
                                JSON.stringify(userData.email)
                                    .slice(1, 3)
                                    .toUpperCase()
                            )}
                        </Avatar>
                    </Link>
                )}

                <Button
                    onClick={signOut}
                    className={styles.logout}
                    id="logout-button"
                >
                    <span className={styles.header__logout_text}>
                        {t('common.logout')}
                    </span>

                    <LogoutIcon />
                </Button>

                <Button onClick={toggleExpanded} className={styles.menu_toggle}>
                    <MenuIcon />
                </Button>
            </div>
        </AppBar>
    );
};

export default Header;
